<template>
  <b-card-code title="" no-body>
    <b-card-body>
      <div class="demo-inline-spacing">
        <b-button variant="gradient-primary" @click="modalBalasContact()">
          <feather-icon icon="ExternalLinkIcon" class="mr-50" />
          <span class="align-middle">Balas Contact</span>
        </b-button>
      </div>
    </b-card-body>

    <!-- list data calon angggota-->
    <template #modal-title>Detail</template>
    <!-- Invoice Description: Total -->
    <table style="width: 100%">
      <b-table-simple hover caption-top responsive class="rounded-bottom mb-0">
        <b-thead head-variant="light">
          <b-tr>
            <b-th colspan="7" class="text-right">
              #ID Contacts: {{ detailDataContacts.id }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-th rowspan="30">
              Data Contacts
            </b-th>
            <b-th class="text-right">
              Anggota :
            </b-th>
            <b-td v-if="detailDataContacts.orang_id"
              ><a
                :href="`/anggota/${detailDataContacts.orang_id}`"
                target="_blank"
                >{{ detailDataContacts.orang_id }}</a
              >
            </b-td>
            <b-td v-if="!detailDataContacts.orang_id"
              >{{ "id anggota tidak ditemukan" }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Email :
            </b-th>
            <b-td> {{ detailDataContacts.contact_email }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Subject :
            </b-th>
            <b-td> {{ detailDataContacts.contact_subject }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Content :
            </b-th>
            <b-td v-html="detailDataContacts.contact_content"></b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Dibuat :
            </b-th>
            <b-td> {{ detailDataContacts.created_at }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Diupdate :
            </b-th>
            <b-td> {{ detailDataContacts.updated_at }}</b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-td colspan="7" variant="secondary" class="text-right"> </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </table>

    <!-- balas contact -->
    <!-- balas contact -->
    <b-modal
      id="modal-balas-contact"
      size="xl"
      title="Balas Contact"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <validation-observer ref="validateBeforeBalasContact">
        <b-form @submit.prevent>
          <b-form-group
            v-if="sendemail.orang_id != null"
            label="User"
            label-for="User"
          >
            <validation-provider #default="{ errors }" name="User">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="user"
                  v-model="sendemail.orang_id"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Masukan Id User"
                  readonly
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Email" label-for="Email">
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="email"
                  v-model="sendemail.contact_email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Masukan Email"
                  readonly
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Subject" label-for="Subject">
            <validation-provider
              #default="{ errors }"
              name="Subject"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="subject"
                  v-model="sendemail.contact_subject"
                  placeholder="Masukan Subject Email"
                  class="form-control-merge"
                  :state="errors.length > 0 ? false : null"
                  name="login-password"
                  autocomplete="on"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Message" label-for="Message">
            <validation-provider
              #default="{ errors }"
              name="Message"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <quill-editor
                  v-model="sendemail.contact_message"
                  :options="snowOptions"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <br />
          <b-button
            variant="primary"
            type="submit"
            block
            :disabled="invalid"
            @click="validateBeforeBalasContact"
          >
            Balas Pesan
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { required, email } from "@validations";

import {
  BTable,
  BButton,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BFormDatepicker,
  BTfoot,
  BFormGroup,
  BAvatar,
  BBadge,
  BCardBody,
  BPagination,
  BFormTextarea,
  BButtonToolbar,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BRow,
  BCard,
  BCardText,
  BCardTitle,
  BCol,
  BModal,
  BFormFile,
} from "bootstrap-vue";
import API from "@/api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { quillEditor } from "vue-quill-editor";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BAvatar,
    BFormFile,
    BCardBody,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BBadge,
    vSelect,
    BFormDatepicker,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BCard,
    BCardText,
    BCardTitle,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BModal,
    BFormTextarea,
    quillEditor,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      currentPage: 1,
      rows: 0,
      perPage: 20,
      // lihat jadwal
      jadwalCurrentPage: 1,
      jadwalRows: 0,
      jadwalPerPage: 20,
      modes: ["multi", "single", "range"],
      search: null,

      detailDataContacts: {},

      sendemail: {
        subject: "",
        message: "",
      },
      status: [
        {
          0: "Register",
          1: "Verify",
          2: "Can't find",
          3: "Input wrong data",
        },
        {
          0: "light-primary",
          1: "light-success",
          2: "light-warning",
          3: "light-warning",
          5: "light-info",
        },
      ],
      selectMode: "multi",
      tanggals: [], //map tanggal krip
      option: [], // store tanggal with option select
      uploadSipSah: "",
      solidColor: [{ bg: "danger", title: "Danger card title" }],

      snowOptions: {
        theme: "snow",
      },

      sendemail: {
        orang_id: "",
        contact_email: "",
        contact_subject: "",
        contact_message: "",
      },
    };
  },

  computed: {},
  methods: {
    validateBeforeBalasContact() {
      this.$refs.validateBeforeBalasContact.validate().then((success) => {
        if (success) {
          this.balasContacts();
        }
      });
    },

    modalBalasContact() {
      this.$bvModal.show("modal-balas-contact");
    },

    async balasContacts() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        this.$swal({
          title: "Apakah kamu yakin?",
          text:
            "Data message ini akan terkirim ke email users, pastikan email,subject dan message yang di isi sudah benar",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, Kirim!",
          cancelButtonText: "Batal",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
          .then((result) => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
            if (result.value) {
              return API.contact.replyMessage({
                contact_email: this.sendemail.contact_email,
                contact_subject: this.sendemail.contact_subject,
                contact_message: this.sendemail.contact_message,
                orang_id: this.sendemail.orang_id,
              });
            }
            return false;
          })
          .then((result) => {
            if (result) {
              this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: "Berhasil Balas Pesan",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
            this.refreshPage();
          })
          .catch((error) => {
            this.handleMsgErrors(error, "terjadi kesalahan silahkan coba lagi");
          })
          .finally(() => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
          });
      } catch (error) {
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async getDetailContacts() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        const { data } = await API.contact.details(
          this.$route.params.id_contacts //get data contact by id
        );
        this.detailDataContacts = data.data; //store data detail di property detailcontacts
        this.sendemail = data.data;

        this.handleMsgSuccess("Berhasil Menampilkan Detail Contacts");
      } catch (error) {
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
  },

  mounted() {
    this.getDetailContacts();
  },
};
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
